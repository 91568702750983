<template>
  <div class="informe-main-container">
    <div class="informe-header">
      <h1 class="typo-headline-4">Informes Semanales</h1>
      <p class="typo-body-2">Accede a los análisis semanales del mercado con comentarios detallados y datos de interés</p>
    </div>

    <div class="informe-content-columns">
      <!-- Left Side Column -->
      <div class="left-column">
        <!-- Newsletter Subscription -->
        <div class="newsletter-container" v-if="shouldShowNewsletter">
          <div class="newsletter-card">
            <div class="newsletter-content">
              <h2 class="typo-headline-6">Suscríbete a nuestro informe semanal</h2>
              
              <!-- Newsletter Form -->
              <div class="newsletter-form-new">
                <input
                  v-model="email"
                  type="email"
                  placeholder="Tu correo electrónico"
                  class="newsletter-input-new"
                />
                <div class="newsletter-checkbox">
                  <input 
                    type="checkbox" 
                    id="newsletter-consent" 
                    v-model="consentChecked"
                  />
                  <label for="newsletter-consent" class="consent-text">
                    Acepto suscribirme a Newsletter *<br>
                    <span class="small-text">Marcando esta casilla acepto que Mercalia Global Market, S.A.U. procese mis datos con el fin de enviarme noticias, eventos y otra información relacionada con las actividades de Mercalia Global Market, S.A.U..</span>
                  </label>
                </div>
                <button
                  @click="subscribeToNewsletter"
                  class="subscribe-button-new"
                  :disabled="isSubscribing || !consentChecked"
                >
                  <span v-if="isSubscribing" class="material-icons spinner">sync</span>
                  <span v-else>Suscribirse</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Only: Informes List -->
        <div class="mobile-informes-container">
          <!-- Informes List -->
          <div class="informes-container">
            <div v-if="loading" class="informes-loading">
              <!-- Skeleton placeholders for loading state - match itemsPerPage -->
              <div v-for="i in itemsPerPage" :key="'mobile-skeleton-' + i" class="informe-item skeleton-item">
                <div class="informe-item-content">
                  <div class="informe-image-container">
                    <div class="skeleton-image"></div>
                  </div>
                  <div class="informe-details">
                    <div class="skeleton-title"></div>
                    <div class="skeleton-actions-container">
                      <div class="skeleton-button"></div>
                      <div class="skeleton-author"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div v-else-if="informes.length === 0" class="informes-empty">
              <p>No hay informes disponibles en este momento.</p>
            </div>
            
            <div v-else class="informes-list">
              <div 
                v-for="informe in informes" 
                :key="informe.nid" 
                class="informe-item"
              >
                <div class="informe-item-content">
                  <div class="informe-image-container" @click="goToInforme(informe.nid, informe.title)">
                    <div class="informe-image" :style="getBackgroundStyle(informe.pic_pc)">
                      <div v-if="!informe.pic_pc" class="fallback-icon">
                        <span class="material-icons-outlined">description</span>
                      </div>
                    </div>
                  </div>
                  <div class="informe-details">
                    <h3 class="informe-title" @click="goToInforme(informe.nid, informe.title)">
                      Informe semanal {{ formatDate(informe.created) }}
                    </h3>
                    <div class="informe-actions">
                      <button @click="goToInforme(informe.nid, informe.title)" class="read-more-button">
                        Leer más...
                      </button>
                      <div class="informe-author">
                        <img src="/sites/all/themes/Porto_sub/img/team/jamadrigal-firma.png" alt="Firma" class="author-signature">
                        <a href="https://www.joseantoniomadrigal.com" target="_blank" class="author-link">
                          Por Jose Antonio Madrigal
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Pagination -->
            <div v-if="totalPages > 1" class="pagination-container">
              <button 
                @click="prevPage" 
                class="pagination-button"
                :disabled="currentPage === 0"
              >
                <span class="material-icons">navigate_before</span>
              </button>
              
              <div class="pagination-info">
                <span>{{ currentPage + 1 }} de {{ totalPages }}</span>
              </div>
              
              <button 
                @click="nextPage" 
                class="pagination-button"
                :disabled="currentPage >= totalPages - 1"
              >
                <span class="material-icons">navigate_next</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Curso Online Ad Sidebar -->
        <div class="course-cursoonline-sidebar">
          <div class="cursoonline-content">
            <div class="cursoonline-text">
              <span class="cursoonline-title">Curso online</span>
              <p>Realiza el curso inicial de Eurekers, en cualquier momento del día y desde cualquier lugar.</p>
              <p class="cursoonline-highlight">Gratuitas las dos primeras horas.</p>
            </div>
            <button @click="goToCourse" class="cursoonline-button mdc-button mdc-button--raised">
              <span class="mdc-button__label">Ver curso</span>
            </button>
          </div>
        </div>

        <!-- Archivo Section -->
        <div class="sidebar-section">
          <h3 class="sidebar-title">Archivo</h3>
          <div class="sidebar-content">
            <!-- Year-Month dropdown structure for archives -->
            <div v-if="archiveLoading" class="archive-loading">
              <div class="loading-spinner"></div>
            </div>
            <div v-else-if="!groupedInformes || groupedInformes.length === 0" class="empty-placeholder">
              No hay informes disponibles.
            </div>
            <div v-else class="archive-tree">
              <!-- Iterate over the sorted array of year objects -->
              <div v-for="yearItem in groupedInformes" :key="yearItem.year" class="archive-year">
                <div class="archive-year-header" @click="toggleYear(yearItem.year)">
                  <span class="material-icons">{{ expandedYears[yearItem.year] ? 'expand_more' : 'chevron_right' }}</span>
                  <span>{{ yearItem.year }}</span>
                </div>
                <div v-if="expandedYears[yearItem.year]" class="archive-months">
                  <!-- Iterate over the sorted array of month objects for this year -->
                  <div v-for="monthItem in yearItem.months" :key="monthItem.month" class="archive-month">
                    <div class="archive-month-header" @click="toggleMonth(yearItem.year, monthItem.month)">
                      <span class="material-icons">{{ expandedMonths[yearItem.year+'-'+monthItem.month] ? 'expand_more' : 'chevron_right' }}</span>
                      <span>{{ monthItem.monthName }}</span>
                    </div>
                    <div v-if="expandedMonths[yearItem.year+'-'+monthItem.month]" class="archive-items">
                      <!-- Iterate over the sorted array of informes for this month -->
                      <div v-for="informe in monthItem.informes" :key="informe.nid" class="archive-item">
                        <router-link :to="`/app/informe/${formatTitleForUrl(informe.title)}`" class="archive-link">
                          {{ formatArchiveDate(informe.title) }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main Content Column - Desktop Only -->
      <div class="main-column">
        <!-- Informes List -->
        <div class="informes-container desktop-only">
          <div v-if="loading" class="informes-loading">
            <!-- Skeleton placeholders for loading state - match itemsPerPage -->
            <div v-for="i in itemsPerPage" :key="'desktop-skeleton-' + i" class="informe-item skeleton-item">
              <div class="informe-item-content">
                <div class="informe-image-container">
                  <div class="skeleton-image"></div>
                </div>
                <div class="informe-details">
                  <div class="skeleton-title"></div>
                  <div class="skeleton-actions-container">
                    <div class="skeleton-button"></div>
                    <div class="skeleton-author"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div v-else-if="informes.length === 0" class="informes-empty">
            <p>No hay informes disponibles en este momento.</p>
          </div>
          
          <div v-else class="informes-list">
            <div 
              v-for="informe in informes" 
              :key="informe.nid" 
              class="informe-item"
            >
              <div class="informe-item-content">
                <div class="informe-image-container" @click="goToInforme(informe.nid, informe.title)">
                  <div class="informe-image" :style="getBackgroundStyle(informe.pic_pc)">
                    <div v-if="!informe.pic_pc" class="fallback-icon">
                      <span class="material-icons-outlined">description</span>
                    </div>
                  </div>
                </div>
                <div class="informe-details">
                  <h3 class="informe-title" @click="goToInforme(informe.nid, informe.title)">
                    Informe semanal {{ formatDate(informe.created) }}
                  </h3>
                  <div class="informe-actions">
                    <button @click="goToInforme(informe.nid, informe.title)" class="read-more-button">
                      Leer más...
                    </button>
                    <div class="informe-author">
                      <img src="/sites/all/themes/Porto_sub/img/team/jamadrigal-firma.png" alt="Firma" class="author-signature">
                      <a href="https://www.joseantoniomadrigal.com" target="_blank" class="author-link">
                        Por Jose Antonio Madrigal
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Pagination -->
          <div v-if="totalPages > 1" class="pagination-container">
            <button 
              @click="prevPage" 
              class="pagination-button"
              :disabled="currentPage === 0"
            >
              <span class="material-icons">navigate_before</span>
            </button>
            
            <div class="pagination-info">
              <span>{{ currentPage + 1 }} de {{ totalPages }}</span>
            </div>
            
            <button 
              @click="nextPage" 
              class="pagination-button"
              :disabled="currentPage >= totalPages - 1"
            >
              <span class="material-icons">navigate_next</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer disclaimer -->
    <div class="informe-disclaimer">
      <p class="disclaimer-text">
        La información contenida en este portal web es meramente informativa y no pretende ser una recomendación para sus inversiones. 
        Cada persona física deberá corroborar y decidir las inversiones más propicias para la gestión de su capital. 
        Los análisis realizados contemplan 2 años históricos para que la información sea más comprensible visualmente. 
        Además las escalas utilizadas son logarítmicas.
      </p>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import { mapGetters } from 'vuex';

export default {
  name: 'InformeMain',
  mixins: [APICaller],
  data() {
    return {
      informes: [],
      archivedInformes: [],
      groupedInformes: {},
      expandedYears: {},
      expandedMonths: {},
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      itemsPerPage: 8,
      loading: true,
      archiveLoading: true,
      email: '',
      isSubscribing: false,
      hasAlreadySubscribed: false,
      consentChecked: false
    };
  },
  computed: {
    ...mapGetters(['_g_ThemeName', '_g_UserFeatures', '_g_UserId']),
    shouldShowNewsletter() {
      // Show only if user is NOT logged in AND has NOT already subscribed
      let isLoggedIn = this._g_UserId != 0;
      return !isLoggedIn && !this.hasAlreadySubscribed;
    }
  },
  mounted() {
    this.fetchInformes();
    this.fetchAllInformes();
    this.checkSubscriptionStatus();
  },
  methods: {
    checkSubscriptionStatus() {
      this.hasAlreadySubscribed = localStorage.getItem('hasSubscribedToInformeNewsletter') === 'true';
    },
    fetchInformes() {
      this.loading = true;
      
      let success = response => {
        this.informes = response.data.informes;
        this.totalPages = response.data.total_pages;
        this.totalItems = response.data.total;
        this.itemsPerPage = response.data.items_per_page;
        this.loading = false;
      };
      
      let url = `/api/informes/${this.currentPage}`;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('400', 'Error al cargar los informes semanales', () => {
        this.loading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    },
    
    fetchAllInformes() {
      this.archiveLoading = true;
      let url = `/api/informes-list`;
      
      const successHandler = new APICaller.SuccessHandler(response => {
        this.archivedInformes = response.data;
        this.groupAndOrganizeInformes();
        this.archiveLoading = false;
      });
      
      const failureHandler = new APICaller.FailureHandler('400', 'Error al cargar el archivo de informes', () => {
        this.archiveLoading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    },
    
    groupAndOrganizeInformes() {
      const grouped = {};
    
      // 1. Group informes by year and month
      this.archivedInformes.forEach(informe => {
        const dateParts = this.extractDateParts(informe.title);
        if (dateParts) {
          const { year, month } = dateParts;
          if (!grouped[year]) grouped[year] = {};
          if (!grouped[year][month]) grouped[year][month] = [];
          grouped[year][month].push(informe);
    
          // Initialize expansion states if not already present
          const monthKey = `${year}-${month}`;
          if (!(year in this.expandedYears)) {
            this.$set(this.expandedYears, year, false);
          }
          if (!(monthKey in this.expandedMonths)) {
            this.$set(this.expandedMonths, monthKey, false);
          }
        }
      });
    
      // 2. Sort years descending
      const sortedYears = Object.keys(grouped)
        .map(yearStr => parseInt(yearStr, 10))
        .sort((a, b) => b - a); 
    
      // 3. Create the final array structure with sorted data
      const finalGroupedData = sortedYears.map(year => {
        const yearData = grouped[year];
        
        // Sort months descending within the current year
        const sortedMonths = Object.keys(yearData)
          .map(monthStr => parseInt(monthStr, 10))
          .sort((a, b) => b - a);
    
        const monthsData = sortedMonths.map(month => {
          const informes = yearData[month];
          
          // Sort informes by day descending within the current month
          informes.sort((a, b) => {
            const dateA = this.extractDateParts(a.title);
            const dateB = this.extractDateParts(b.title);
            if (dateA && dateB) {
              return dateB.day - dateA.day; // Sort days descending
            }
            return 0; // Fallback if dates can't be parsed
          });
    
          return {
            month,
            monthName: this.getMonthName(month),
            informes,
          };
        });
    
        return {
          year,
          months: monthsData,
        };
      });
    
      // 4. Assign the sorted array structure to the component data
      this.groupedInformes = finalGroupedData; 
    },
    
    extractDateParts(title) {
      // Extract date from title (format: dd/mm/yyyy)
      const dateRegex = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
      const match = title.match(dateRegex);
      
      if (match) {
        return {
          day: parseInt(match[1], 10),
          month: parseInt(match[2], 10),
          year: parseInt(match[3], 10)
        };
      }
      
      return null;
    },
    
    toggleYear(year) {
      this.$set(this.expandedYears, year, !this.expandedYears[year]);
    },
    
    toggleMonth(year, month) {
      const key = `${year}-${month}`;
      this.$set(this.expandedMonths, key, !this.expandedMonths[key]);
    },
    
    getMonthName(month) {
      const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ];
      return months[month - 1]; // Adjust for 0-based array
    },
    
    formatArchiveDate(title) {
      // Extract date from title and format it nicely
      const dateParts = this.extractDateParts(title);
      if (dateParts) {
        // Format as dd/mm/yyyy
        return `${dateParts.day.toString().padStart(2, '0')}/${dateParts.month.toString().padStart(2, '0')}/${dateParts.year}`;
      }
      return title;
    },
    
    getBackgroundStyle(imagePath) {
      if (!imagePath) {
        return { backgroundColor: 'var(--color-active-surface)' };
      }
      else {
        imagePath = 'https://www.eurekers.com' + imagePath;
      }
      return {
        backgroundImage: `url(${imagePath})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    },
    
    handleImageError(event) {
      event.target.parentNode.classList.add('image-error');
      const iconElement = document.createElement('div');
      iconElement.classList.add('fallback-icon');
      iconElement.innerHTML = '<span class="material-icons-outlined">description</span>';
      event.target.parentNode.appendChild(iconElement);
    },
    
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
        this.fetchInformes();
      }
    },
    
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.fetchInformes();
      }
    },
    
    goToInforme(nid, title) {
      if (!title) {
        console.error("Informe title is missing, cannot navigate.");
        // Find the informe by nid if title is missing (less ideal)
        const informe = this.informes.find(inf => inf.nid === nid);
        if (informe && informe.title) {
          title = informe.title;
        } else {
          // Attempt to find in archived informes if not in current page list
           const archivedInforme = this.archivedInformes.find(inf => inf.nid === nid);
           if (archivedInforme && archivedInforme.title) {
              title = archivedInforme.title;
           } else {
               this.$vueOnToast.pop('error', 'Error', 'No se pudo encontrar el título del informe.');
               return; // Cannot navigate without a title
           }
        }
      }
      const formattedTitle = this.formatTitleForUrl(title);
      this.$router.push(`/app/informe/${formattedTitle}`);
    },
    
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    
    subscribeToNewsletter() {
      if (!this.validateEmail(this.email)) {
        this.$vueOnToast.pop('error', 'Error', 'Por favor, introduce un correo electrónico válido');
        return;
      }

      if (!this.consentChecked) {
        this.$vueOnToast.pop('error', 'Error', 'Debes aceptar los términos de la suscripción');
        return;
      }
      
      this.isSubscribing = true;
      
      let params = new URLSearchParams();
      params.append('email', this.email);
      
      let success = (response) => {
        if (response.data.success) {
          localStorage.setItem('hasSubscribedToInformeNewsletter', 'true');
          this.hasAlreadySubscribed = true;
          this.$vueOnToast.pop('success', 'Suscripción completada', 'Te has suscrito correctamente al informe semanal');
        } else {
          this.$vueOnToast.pop('error', 'Error en la suscripción', response.data.error || 'Ha ocurrido un error durante la suscripción');
        }
        this.email = '';
        this.isSubscribing = false;
        this.consentChecked = false;
      };
      
      let url = '/api/suscribir-informe-simple';
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('401', 'Error al suscribirse al informe semanal', () => {
        this.isSubscribing = false;
      });
      
      this._postAPICall(url, params, successHandler, failureHandler);
    },
    
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    
    formatTitleForUrl(title) {
      // Assuming title is in 'dd/mm/yyyy' format
      if (!title) return '';
      const parts = title.split('/');
      if (parts.length === 3) {
        // Return 'dd-mm-yyyy' format, URL encoded
        return encodeURIComponent(`${parts[0]}-${parts[1]}-${parts[2]}`);
      }
      // Fallback for unexpected formats - encode the original title
      return encodeURIComponent(title); 
    },

    goToCourse() {
      // Direct to online.eurekers.com
      window.open('https://online.eurekers.com', '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.informe-main-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.informe-header {
  margin-bottom: 40px;
  text-align: center;
  
  h1 {
    color: var(--color-primary);
    margin-bottom: 12px;
    font-weight: 600;
  }
  
  p {
    color: var(--color-text-secondary);
    max-width: 600px;
    margin: 0 auto;
  }
}

// Two-column layout
.informe-content-columns {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 40px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

// Left column (sidebar)
.left-column {
  width: 100%;
  
  @media (min-width: 768px) {
    width: 30%;
    flex-shrink: 0;
  }
}

// Main content column
.main-column {
  width: 100%;
  
  @media (min-width: 768px) {
    width: 70%;
  }
}

// Mobile/Desktop specific containers
.mobile-informes-container {
  display: block;
  margin-bottom: 32px;
  
  @media (min-width: 768px) {
    display: none;
  }
}

.desktop-only {
  display: none;
  
  @media (min-width: 768px) {
    display: block;
  }
}

// Newsletter section styles
.newsletter-container {
  margin-bottom: 32px;
}

.newsletter-card {
  background-color: var(--color-surface);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(15, 15, 26, 0.05);
}

.newsletter-content {
  padding: 24px;
  
  h2 {
    color: var(--color-primary);
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
  }
}

.newsletter-form-new {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.newsletter-input-new {
  padding: 12px 16px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  outline: none;
  box-shadow: 0 0 0 1px var(--color-outline);
  transition: box-shadow 0.2s ease;

  &:focus {
    box-shadow: 0 0 0 2px var(--color-highlight);
  }
}

.newsletter-checkbox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
  
  input {
    margin-top: 4px;
  }
  
  label {
    font-size: 14px;
    color: var(--color-text-secondary);
    cursor: pointer;
  }
  
  .small-text {
    font-size: 12px;
    display: block;
    margin-top: 4px;
  }
}

.subscribe-button-new {
  background-color: var(--color-highlight);
  color: var(--color-text-on-highlight);
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(15, 15, 26, 0.08);

  &:hover:not(:disabled) {
    background-color: var(--highlight-hover);
    color: var(--color-text-on-highlight);
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  .spinner {
    animation: spin 1s infinite linear;
  }
}

// Promo image
.promo-container {
  margin-bottom: 32px;
  
  .promo-link {
    display: block;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-4px);
    }
  }
  
  .promo-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(15, 15, 26, 0.08);
  }
}

// Sidebar sections
.sidebar-section {
  margin-bottom: 32px;
  background-color: var(--color-surface);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(15, 15, 26, 0.05);
  
  .sidebar-title {
    padding: 16px 24px;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-outline);
  }
  
  .sidebar-content {
    padding: 16px 24px;
  }
  
  .empty-placeholder {
    color: var(--color-text-secondary);
    font-style: italic;
    text-align: center;
    padding: 12px 0;
  }
}

// Archive tree styles
.archive-tree {
  font-size: 14px;
}

.archive-loading {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  
  .loading-spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid var(--color-outline);
    border-top-color: var(--color-highlight);
    animation: spin 1s infinite linear;
  }
}

.archive-year {
  margin-bottom: 8px;
}

.archive-year-header, 
.archive-month-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 0;
  color: var(--color-text-primary);
  transition: color 0.2s ease;
  
  &:hover {
    color: var(--color-highlight);
  }
  
  .material-icons {
    font-size: 18px;
    margin-right: 4px;
  }
}

.archive-months {
  margin-left: 16px;
}

.archive-month {
  margin-bottom: 4px;
}

.archive-items {
  margin-left: 22px;
}

.archive-item {
  padding: 4px 0;
}

.archive-link {
  color: var(--color-text-secondary);
  text-decoration: none;
  transition: color 0.2s ease;
  display: inline-block;
  padding: 2px 0;
  
  &:hover {
    color: var(--color-highlight);
    text-decoration: underline;
  }
  
  &.router-link-active {
    color: var(--color-highlight);
    font-weight: 500;
  }
}

// Informes list styles
.informes-container {
  position: relative;
  min-height: 300px;
}

.informes-loading, .informes-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  color: var(--color-text-secondary);
  gap: 24px;
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid var(--color-outline);
    border-top-color: var(--color-highlight);
    animation: spin 1s infinite linear;
    margin-bottom: 16px;
  }
}

@keyframes shine {
  to {
    background-position: right -100px top 0;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Skeleton loading styles
.skeleton-item {
  background-color: var(--color-surface);
  opacity: 0.8;
  transition: none;
  transform: none !important;
  width: 100%;
  
  &:hover {
    transform: none !important;
  }
}

.skeleton-image {
  background-color: var(--color-background);
  animation: shine 1.5s infinite linear;
  background-image: linear-gradient(
    to right,
    var(--color-background) 0%,
    var(--color-active-surface) 20%,
    var(--color-background) 40%
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  height: 180px;
  
  @media (min-width: 576px) {
    height: 100%;
  }
}

.skeleton-title {
  height: 20px;
  width: 80%;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: var(--color-background);
  animation: shine 1.5s infinite linear;
  background-image: linear-gradient(
    to right,
    var(--color-background) 0%,
    var(--color-active-surface) 20%,
    var(--color-background) 40%
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
}

.skeleton-actions-container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.skeleton-button {
  height: 32px;
  width: 100px;
  border-radius: 8px;
  background-color: var(--color-background);
  animation: shine 1.5s infinite linear;
  background-image: linear-gradient(
    to right,
    var(--color-background) 0%,
    var(--color-active-surface) 20%,
    var(--color-background) 40%
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
}

.skeleton-author {
  height: 24px;
  width: 180px;
  border-radius: 4px;
  background-color: var(--color-background);
  animation: shine 1.5s infinite linear;
  background-image: linear-gradient(
    to right,
    var(--color-background) 0%,
    var(--color-active-surface) 20%,
    var(--color-background) 40%
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
}

.informes-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.informe-item {
  background-color: var(--color-surface);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(15, 15, 26, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-4px);
  }
}

.informe-item-content {
  display: flex;
  flex-direction: column;
  
  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.informe-image-container {
  flex-shrink: 0;
  cursor: pointer;
  
  @media (min-width: 576px) {
    width: 150px;
  }
}

.informe-image {
  height: 180px;
  position: relative;
  
  @media (min-width: 576px) {
    height: 100%;
  }
}

.fallback-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .material-icons-outlined {
    font-size: 48px;
    color: var(--color-text-secondary);
  }
}

.informe-details {
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.informe-title {
  margin: 0 0 16px 0;
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
}

.informe-actions {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.read-more-button {
  background-color: var(--color-highlight);
  color: var(--color-text-on-highlight);
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  align-self: flex-start;
  
  &:hover {
    background-color: var(--color-highlight);
    color: var(--color-text-on-highlight);
  }
}

.informe-author {
  display: flex;
  align-items: center;
  gap: 8px;
  
  .author-signature {
    height: 24px;
    width: auto;
  }
  
  .author-link {
    color: var(--color-text-secondary);
    font-size: 14px;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
      color: var(--color-primary);
    }
  }
}

// Pagination styles
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.pagination-button {
  background: var(--color-surface);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-primary);
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(15, 15, 26, 0.08);
  
  &:hover:not(:disabled) {
    background-color: var(--color-active-surface);
    transform: translateY(-2px);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.pagination-info {
  margin: 0 16px;
  color: var(--color-text-secondary);
  font-size: 14px;
  font-weight: 500;
}

// Footer disclaimer
.informe-disclaimer {
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid var(--color-outline);
  
  .disclaimer-text {
    font-size: 12px;
    color: var(--color-text-secondary);
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
  }
}

// Apply layout styles to skeleton's content wrapper
.skeleton-item .informe-item-content {
  display: flex;
  flex-direction: column;
  
  @media (min-width: 576px) {
    flex-direction: row;
  }
}

// Apply same padding and flex properties to skeleton details
.skeleton-item .informe-details {
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

// Curso Ad Sidebar styles (adapted from InformeDetail)
.course-cursoonline-sidebar {
  background: linear-gradient(135deg, var(--color-highlight) 0%, #0056b3 100%); // Add gradient background
  border-radius: 16px; // Larger radius
  padding: 24px; // Increased padding
  margin-bottom: 32px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15); // Enhanced shadow
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1); // Subtle border
  overflow: hidden; // For the decorative elements

  // Add decorative elements
  &::before {
    content: '';
    position: absolute;
    top: -20px;
    right: -20px;
    width: 120px;
    height: 120px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: -30px;
    width: 160px;
    height: 160px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 50%;
    z-index: 0;
  }

  .cursoonline-content {
    display: flex;
    flex-direction: column; // Stack vertically in sidebar
    align-items: flex-start;
    gap: 18px; // Increased gap
    position: relative;
    z-index: 1;
  }

  .cursoonline-text {
    color: white; // Use white text on solid highlight background

    .cursoonline-title {
      font-size: 22px; // Larger title
      font-weight: 700;
      display: block;
      margin-bottom: 12px; // More space
      color: white;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); // Add text shadow
    }

    p {
      margin: 6px 0; // Increased vertical margin
      font-size: 15px; // Slightly larger text
      color: white;
      opacity: 0.95;
      line-height: 1.4; // Better readability
    }

    .cursoonline-highlight {
      font-weight: 600;
      font-size: 16px; // Larger highlight text
      color: white;
      background-color: rgba(255, 255, 255, 0.25); // More visible
      padding: 6px 12px; // Increased padding
      border-radius: 6px;
      display: inline-block;
      margin-top: 8px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); // Subtle text shadow
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow
    }
  }

  .cursoonline-button {
    background-color: white;
    color: var(--color-highlight);
    border: none;
    border-radius: 8px; // Larger radius
    padding: 10px 20px; // Larger button
    font-size: 15px; // Larger font size
    font-weight: 600;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); // Enhanced shadow
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    align-self: flex-start; // Align button to the start
    margin-top: 6px; // Add some space above the button

    &:hover {
      background-color: white;
      transform: translateY(-3px); // More pronounced hover effect
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    }
  }
}

// Promo image (Keep original styles commented or remove if desired)
/*
.promo-container {
  margin-bottom: 32px;
  
  .promo-link {
    display: block;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-4px);
    }
  }
  
  .promo-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(15, 15, 26, 0.08);
  }
}
*/
</style> 